import React, { useState, useEffect } from "react";
import style from "./organizer.module.css";
import NavBar from "../NavBar/NavBar";
import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { toast } from 'react-toastify';

import { listLocations, addLocation } from "../../../Data/location";
import { saveImage, listCurrencies } from "../../../Data/general";
import { getByLocation } from "../../../Data/plan";
import LocationSubmit from "./LocationSubmit";
import SeatingSubmit from "./SeatingSubmit";
import { useTranslation } from "react-i18next";
import { standartSeats, defaultType, standartMap } from "../../../Services/helpers";
import { eventPostponePrepare, eventPostponed } from "../../../Data/event";

const EventSubmit = ({ form, setForm, submit, onDismiss, isAdmin, delEvent, disEvent, pendingApiCall }) => {

  const { t } = useTranslation();
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  // console.log(standartMap);
  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    setDisabled(true)
    if (form.title === undefined || form.title === '') return
    if (form.description === undefined || form.description === '') return
    if (form.location === undefined) return
    if (form.startDate === undefined) return
    if (form.doorOpenDate === undefined) return
    if (form.endDate === undefined) return
    if (form.currency === undefined) return
    if (form.smimage === undefined) return
    if (form.image === undefined) return
    if (form.type === undefined || form.type === '') return
    setDisabled(false)
  }, [form])

  const [fieldmodal, setFieldModal] = useState(false);
  const [planmodal, setPlanModal] = useState(false);
  const [planedited, setPlanEdited] = useState(false);
  const [fieldForm, setFieldForm] = useState({ plan: { isSimple: true, map: standartMap, standartSeatTypes: [...standartSeats] } });
  const [planForm, setPlanForm] = useState({ isSimple: true, map: { ...standartMap, sample: true }, standartSeatTypes: [...standartSeats] });
  const [currencies, setCurrencies] = useState([]);
  const [ordersInfo, setOrdersInfo] = useState(null);
  const [fieldsSelectorOpen, setFieldsSelectorOpen] = useState(false);

  const [fieldsList, setFieldsList] = useState([]);
  const [prevPlans, setPrevPlans] = useState(null);

  const initFetch = async () => {
    setFieldsList(await listLocations());
    setCurrencies(await listCurrencies());
  }

  const onFieldClose = () => {
    setFieldModal(false);
  };

  const onPlanClose = () => {
    setPlanModal(false);
  };


  const changeLocation = async (nm) => {
    let loc = fieldsList.find((it) => it.name == nm);
    let nform = { ...form, location: { ...loc } };
    if (loc.plan) {
      let pln = { ...loc.plan };
      let typs = {};
      if (pln.map?.rows) {
        pln.map.seatTypes.map((it) => { typs[it.types] = it; });
        pln.map.seatTypes = typs;
        pln.simple = false;
      } else pln.simple = true;
      nform.plan = pln;
    }
    setPrevPlans(await getByLocation(loc));
    setForm(nform);
  }

  const changeImg = (e) => {
    if (e.target.files[0].size > 5000000) {
      toast('Yüklenen resim 5MB fazla olamaz');
      e.target.value = "";
      e.target.type = "file";
    } else {
      let list = Array.from(e.target.files);
      setForm({ ...form, image: list[0] });
    }
  }

  const changeSImg = (e) => {
    if (e.target.files[0].size > 5000000) {
      toast('Yüklenen resim 5MB fazla olamaz');
      e.target.value = "";
      e.target.type = "file";
    } else {
      let list = Array.from(e.target.files);
      setForm({ ...form, smimage: list[0] })
    }
  }

  const onCancelEvent = async (id) => {
    let info = await eventPostponePrepare(id);
    if (info) setOrdersInfo(info);
  }

  const cancelEve = async (id) => {
    let info = await eventPostponed(id);
    if (info) {
      toast.success(info.length + ' Müşteri başarıyla bilgilendirildi');
      setOrdersInfo(false);
    } else toast.success('Hata. Tekrar deneyiniz.');
  }



  const onFieldSubmit = async () => {
    await saveImage(fieldForm.image).then(async (imgUrl) => {
      if (!imgUrl) toast.error(t('error_try_again'));
      else {
        let _location = {
          ...fieldForm,
          image: imgUrl,
          isApproved: isAdmin
        }
        await addLocation(_location).then((location) => {
          if (!location) toast.error(t('error_try_again'));
          else {
            setFieldModal(false);
            setFieldForm({});
            initFetch();
            toast.success(t('location_added'));
          }
        });
      }
    });
  };

  function formatNumberWithCommas(x) {
    const parts = x.toString().split(".");
    // Format the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Recombine with the decimal part if it exists
    return parts.join(".");
  }

  useEffect(() => {
    initFetch()
  }, []);

  const RepeatedRow = ({
    id,
    type,
    name,
    placeholder,
    onClick1,
    id2,
    type2,
    name2,
    placeholder2,
    onClick2,
  }) => {
    return (
      <>
        <Stack
          display="flex"
          sx={{ flexDirection: { xs: "column", sm: "row" } }}
        >
          <input
            id={id}
            className="mb-4 modalFormInput w-100"
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick1}
          />
          <input
            id={id2}
            className="mb-4 modalFormInput w-100"
            type={type2}
            name={name2}
            placeholder={placeholder2}
            onChange={onChange}
            onClick={onClick2}
          />
        </Stack>
      </>
    );
  };

  const [countries, setCountries] = useState([]);
  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const [currencySelectorOpen, setCurrencySelectorOpen] = useState(false);

  return (
    <Box
      style={{
        height: "100vh",
        backgroundColor: "#fcf2e2",
      }}
    >
      <NavBar color="#ffd283" />
      <Modal open={fieldmodal} onClose={onFieldClose}>
        <LocationSubmit
          form={fieldForm}
          setForm={setFieldForm}
          submit={onFieldSubmit}
          onDismiss={onFieldClose}
        />
      </Modal>
      {ordersInfo ? (
        <Modal open={!!ordersInfo} onClose={() => { setOrdersInfo(null) }}>
          <Box sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "#fff0d7",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}>
            {/* Detail parent Stack */}
            <Stack display="flex" direction="column" spacing={2}>
              <strong>Etlinliği iptal etmek istiyorsunuz?</strong>
              <span style={{ fontSize: 16, marginTop: 20 }}>{ordersInfo.length} müşteri bilgilendirilecek</span>
              <span style={{ fontSize: 16, marginBottom: 10 }}>Toplam {ordersInfo[0].currency.symbol}<strong>{formatNumberWithCommas(ordersInfo.reduce((acc, oi) => acc += oi.total, 0)?.toString())}</strong> İade edilmeli!</span>
              <ul style={{ maxHeight: 150, overflow: 'auto' }}>
                {ordersInfo.map((oi) => (
                  <li><a target="_blank" href={"https://dashboard.stripe.com/payments/" + oi.paymentSecret?.split("_secret_")[0]}>{oi.customerName} {oi.quantity}adet Toplam: {oi.currency.symbol}<strong>{formatNumberWithCommas(oi.total?.toString())} </strong></a></li>
                ))}

              </ul>

              <button className={style.button} onClick={() => { if (window.confirm("Etkinlik iptal etmek istediğinizde emin misini?")) cancelEve(form._id); }}>

                İptal et
              </button>
            </Stack>
          </Box>
        </Modal>
      ) : null}

      <Modal open={planmodal} onClose={onPlanClose}>
        <SeatingSubmit prevPlans={prevPlans} setPrevPlans={setPrevPlans} location={form?.location} currency={form?.currency?.symbol} plan={form.plan} changeplan={(r) => { setForm({ ...form, plan: r }) }} onFieldClose={onPlanClose} />
      </Modal>
      <div
        className="container mt-3 p-0"
        // d-flex justify-content-center align-items-center
        //  mt-3
        style={{ height: "75vh" }}
      >
        <div className={style.submit}>
          <div
            onClick={onDismiss}
            style={{ cursor: "pointer" }}
            className="text-end me-2"
          >
            Kapat
          </div>
          <div style={{ height: "calc(100% - 30px)" }}>
            <h2 className="mb-5 ms-2" style={{ color: "#df0c62" }}>
              ETKİNLİK BİLGİLERİ
            </h2>
            <form
              className={`container-fluid ${style.formScroll}`}
              style={{ height: "calc(100% - 40px)", overflowY: "auto" }}
            >
              <Stack display="flex" direction="column">
                <Stack
                  display="flex"
                  sx={{ flexDirection: { xs: "column", sm: "row" } }}
                >
                  <input
                    className="mb-4 modalFormInput w-100"
                    type="text"
                    name="title"
                    placeholder="Etkinlik Adı"
                    value={form.title}
                    onChange={onChange}
                  />
                  <Box
                    position="relative"
                    className="mb-4 modalFormInput w-100"
                  >
                    <Autocomplete
                      sx={{ width: "calc(100% - 40px)" }}
                      options={fieldsList}
                      open={fieldsSelectorOpen}
                      onOpen={() => {
                        setFieldsSelectorOpen(true);
                      }}
                      onClose={() => {
                        setFieldsSelectorOpen(false);
                      }}
                      value={form.location}
                      onChange={(e) => {
                        changeLocation(e.target.innerText);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Etkinlik Sahnesi"
                        />
                      )}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    >
                      <Add
                        sx={{ color: "#df0c62" }}
                        onClick={() => setFieldModal(true)}
                      />
                    </IconButton>
                  </Box>
                </Stack>

                {/* Description And Date */}
                <Stack
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ flexDirection: { xs: "column", sm: "row" } }}
                >
                  <Box className="mb-4 modalFormInput w-100">
                    <textarea
                      style={{
                        outline: "none",
                        width: "97%",
                        border: "none",
                        color: "#df0c62",
                        backgroundColor: "transparent",
                      }}
                      className={`w-100 ${style.textArea}`}
                      type="text"
                      name="description"
                      placeholder="Etkinlik Açıklaması"
                      value={form.description}
                      onChange={onChange}
                    />
                  </Box>

                  <Stack
                    display="flex"
                    direction="column"
                    alignItems="start"
                    width="100%"
                    className="mb-4 modalFormInput"
                  >
                    <Typography ml={2}>Etkinlik Tarihi</Typography>
                    <input
                      style={{ width: "95%" }}
                      type="datetime-local"
                      name="startDate"
                      className=" w-100"
                      value={form.startDate}
                      onChange={onChange}
                    />
                  </Stack>
                </Stack>

                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ flexDirection: { xs: "column", sm: "row" } }}
                >
                  <Stack
                    display="column"
                    direction="column"
                    justifyContent="center"
                    width="100%"
                  >
                    <Typography ml={2}>Etkinlik Kapı Açılış Tarihi</Typography>
                    <input
                      style={{ width: "95%" }}
                      className="mb-4 modalFormInput"
                      type="datetime-local"
                      name="doorOpenDate"
                      placeholder="Etkinlik Kapı Açılış Tarihi"
                      value={form.doorOpenDate}
                      onChange={onChange}
                    />
                  </Stack>

                  <Stack
                    display="flex"
                    direction="column"
                    className="mb-4 modalFormInput w-100"
                  >
                    <Typography>Etkinlik Bitiş Tarihi</Typography>
                    <input
                      className="w-100"
                      type="datetime-local"
                      name="endDate"
                      placeholder="Etkinlik Bitiş Tarihi"
                      value={form.endDate}
                      onChange={onChange}
                    />
                  </Stack>
                </Stack>

                {/* currency Pick */}
                <Stack display="flex" direction="row" spacing={2}>
                  <div className="mb-4 modalFormInput w-100">
                    <input
                      className="mb-4 modalFormInput w-100"
                      type="text"
                      name="type"
                      placeholder="Etkinlik Türü"
                      value={form.type}
                      onChange={onChange}
                    />
                  </div>
                  <div className="mb-4 modalFormInput w-100">
                    <Autocomplete
                      options={currencies || []}
                      open={currencySelectorOpen}
                      onOpen={() => {
                        setCurrencySelectorOpen(true);
                      }}
                      onClose={() => {
                        setCurrencySelectorOpen(false);
                      }}
                      value={form.currency}
                      onChange={(e) => {
                        setForm({ ...form, currency: currencies.find(it => it.name == e.target.innerText) });
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Etkinlik Para Birimi"
                        />
                      )}
                    />
                  </div>
                </Stack>

                <div className="d-flex flex-row">
                  <Box
                    className="mb-4  modalFormInput d-flex align-items-center justify-content-between"
                    onClick={(e) => { document.querySelector('#locationImage').click() }}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    Kare Resim (800px X 800px)(1x1)
                    {form.smimage ? (
                      <>
                        {typeof form.smimage != 'string' ? (
                          <img src={URL.createObjectURL(form.smimage)} height="50px" width="auto" />
                        ) : (
                          <img src={CDN_URL + form.smimage} height="50px" width="auto" />
                        )}
                      </>
                    ) : null}
                  </Box>
                  <input
                    className="mb-4"
                    type="file"
                    id="locationImage"
                    value={form.smimage ? form.smimage.filename : null}
                    name="smimage"
                    onChange={(e) => { changeSImg(e) }}
                  />
                </div>

                <div className="d-flex flex-row">
                  <Box
                    className="mb-4 modalFormInput d-flex align-items-center justify-content-between"
                    onClick={(e) => { document.querySelector('#locationImage2').click() }}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    Resim (800px X 1200px)(2x3)
                    {form.image ? (
                      <>
                        {typeof form.image != 'string' ? (
                          <img src={URL.createObjectURL(form.image)} height="50px" width="auto" />
                        ) : (
                          <img src={CDN_URL + form.image} height="50px" width="auto" />
                        )}
                      </>
                    ) : null}
                  </Box>
                  <input
                    className="mb-4"
                    type="file"
                    id="locationImage2"
                    value={form.image ? form.image.filename : null}
                    name="image"
                    onChange={(e) => { changeImg(e) }}
                  />
                </div>
                <div className="d-flex flex-row">
                  <Box
                    className="mb-4  modalFormInput d-flex align-items-center justify-content-between"
                    onClick={() => { setPlanModal(true); setPlanForm(form.plan); setPlanEdited(true); }}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    {!planedited ? (
                      <>Oturma Düzeni Tanımla</>
                    ) : (
                      <>Oturma Düzeni:
                        <FormControl >
                          <RadioGroup
                            sx={{
                              margin: '10px 0',
                              color: "#df0c62",
                              display: 'flex',
                              flexDirection: 'row',
                              '&.Mui-checked': {
                                color: "#df0c62",
                              },
                            }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="isSimple"
                            value={form.plan.isSimple ? 'true' : 'false'}
                            onChange={(e) => { setForm({ ...form, plan: { ...form.plan, isSimple: (e.target.value == 'true' ? true : false) } }) }}
                          >
                            <FormControlLabel value="true" control={<Radio sx={{
                              color: "#df0c62",
                              '&.Mui-checked': {
                                color: "#df0c62",
                              },
                            }} />} label="Basit" />
                            <FormControlLabel value="false" control={<Radio sx={{
                              color: "#df0c62",
                              '&.Mui-checked': {
                                color: "#df0c62",
                              },
                            }} />} label="Planlı" />
                          </RadioGroup>
                        </FormControl>
                      </>
                    )}
                  </Box>
                  {isAdmin ? (
                    <>
                      <div className="mb-4 d-flex flex-row justify-content-between modalFormInput w-50">
                        <span className="w-80 d-flex align-items-center">Öne çikar</span>
                        <FormControl >
                          <RadioGroup
                            sx={{
                              margin: '10px 0',
                              color: "#df0c62",
                              display: 'flex',
                              flexDirection: 'row',
                              '&.Mui-checked': {
                                color: "#df0c62",
                              },
                            }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="isFronted"
                            value={form.isFronted}
                            onChange={onChange}
                          >
                            <FormControlLabel value="true" control={<Radio sx={{
                              color: "#df0c62",
                              '&.Mui-checked': {
                                color: "#df0c62",
                              },
                            }} />} label="Evet" />
                            <FormControlLabel value="false" control={<Radio sx={{
                              color: "#df0c62",
                              '&.Mui-checked': {
                                color: "#df0c62",
                              },
                            }} />} label="Hayır" />
                          </RadioGroup>
                        </FormControl>

                      </div>



                    </>
                  ) : null}



                </div>

                <div className="d-flex flex-row">
                  {
                    isAdmin && form._id ? (
                      <Box
                        className="mb-4  modalFormInput"
                      >
                        <span style={{ fontSize: 30 }}>⚠️</span>
                        {form.canceled ? (
                          <strong>İptal Edildi: {form.canceled.toString()}</strong>
                        ) : (<span onClick={() => { onCancelEvent(form._id); }} style={{ float: 'right', margin: '0 10px', borderBottom: '1px solid', cursor: "pointer" }}>İptal Et</span>)}
                        <span onClick={() => { if (window.confirm("Etkinliği pasif yapmak istediğinizde emin misiniz?")) disEvent(form._id); }} style={{ float: 'right', margin: '0 10px', borderBottom: '1px solid', cursor: "pointer" }}>Pasif Yap</span>
                        <span onClick={() => { if (window.confirm("Etkinlik silmek istediğinizde emin misiniz?")) delEvent(form._id); }} style={{ float: 'right', margin: '0 10px', borderBottom: '1px solid', cursor: "pointer" }}>Sil</span>

                      </Box>
                    ) : null
                  }

                  {isAdmin ? (
                    <div className="mb-4 d-flex flex-row justify-content-between modalFormInput w-50">
                      <span className="w-80 d-flex align-items-center">Satış Sona Erdi</span>
                      <FormControl >
                        <RadioGroup
                          sx={{
                            margin: '10px 0',
                            color: "#df0c62",
                            display: 'flex',
                            flexDirection: 'row',
                            '&.Mui-checked': {
                              color: "#df0c62",
                            },
                          }}
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="isEnded"
                          value={form.isEnded}
                          onChange={onChange}
                        >
                          <FormControlLabel value="true" control={<Radio sx={{
                            color: "#df0c62",
                            '&.Mui-checked': {
                              color: "#df0c62",
                            },
                          }} />} label="Evet" />
                          <FormControlLabel value="false" control={<Radio sx={{
                            color: "#df0c62",
                            '&.Mui-checked': {
                              color: "#df0c62",
                            },
                          }} />} label="Hayır" />
                        </RadioGroup>
                      </FormControl>

                    </div>
                  ) : null}
                </div>




              </Stack>
              <Stack
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ flexDirection: { xs: "column", sm: "row" } }}
              >
                <Stack
                  display="column"
                  direction="column"
                  justifyContent="center"
                  width="100%"
                >
                  <Typography ml={2}>Bilet satış URL</Typography>
                  <input
                    style={{ width: "95%" }}
                    className="mb-4 modalFormInput"
                    type="text"
                    name="saleUrl"
                    placeholder="Bilet satış URL"
                    value={form.saleUrl}
                    onChange={onChange}
                  />
                </Stack>
              </Stack>

              <Typography className={`${style.note} p-5`}>
                *Yükleyecek olduğunuz kare afiş 800x800 px ölçüsünden fazla,
                dikdörtgen afiş 800x1200 px ölçüsünde, minimum 150 dpi
                olmalıdır. Aksi taktirden sistem onayından geçemez.
              </Typography>
            </form>
          </div>
        </div>
      </div>
      <div className="d-flex fixed-bottom">
        <div style={{ backgroundColor: "#feb659", width: "60px" }}></div>
        <div style={{ backgroundColor: "#ffd283", width: "20px" }}></div>
        <button className={`${(disabled || pendingApiCall) ? style.button_disabled : style.button}`} onClick={submit} disabled={disabled || pendingApiCall}>
          {
            pendingApiCall &&
            <div class="spinner-border spinner-border-sm" role="status">
            </div>
          }
          {form._id ? "Kaydet" : "Etkinlik Ekle"}
        </button>
      </div>
    </Box>
  );
};

export default EventSubmit;
