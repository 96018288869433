import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";
import { CssBaseline } from "@mui/material";
import Register from "./Pages/Register/Register";
import Events from "./Pages/Events";
import EventPage from "./Pages/EventPage";
import BuyTicket from "./Pages/BuyTicket";
import Profile from "./Pages/Profile";
import Congratulations from "./Pages/Congratulations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminPanel from "./Pages/Panel/Pages/AdminPanel";
import OrganizerPanel from "./components/Panel/OrganizerPanel";
import TicketDoc from "./Pages/TicketDoc";
import HotSale from "./Pages/Hot-Sale";

import AddSponsor from "./components/Panel/AdminPanel/Sponsor/Add Sponsor/AddSponsor";
import ErrorBoundary from "./components/ErrorBoundary.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./font.css";
import "./addings.css";
import RequestsDesktop from "./components/Panel/AdminPanel/Requests/Desktop/RequestsDesktop";
import SponsorTemplate from "./components/Panel/AdminPanel/Sponsor/SponsorTemplate/SponsorTemplate";

import { stripeConfig } from "./Data/payments";
import { loadStripe } from "@stripe/stripe-js";
import MailTemplate from "./components/mail/MailTemplate";
import InfoPage from "./Pages/infos/InfoPage";
import Barcode from "./components/barcode/Barcode";
import BarcodeScanner from "./components/barcode/BarcodeScanner";
import FindTicket from "./components/findTicket/FindTicket.js";
import ReportProblem from "./components/reportProblem/ReportProblem.js";
import Unsubscribe from "./Pages/Unsubscribe.jsx";

const App = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const stripeInit = async () => {
    let key = await stripeConfig();
    setStripePromise(loadStripe(key));
  };
  const localeInit = async () => {
    let _test = await localStorage.getItem("e-ticket-al language");
    if (!_test) {
      await localStorage.setItem("e-ticket-al language", "EN");
      await localStorage.setItem("e- icket-al currency", "$");
    }
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/" element={<Home />} />

        <Route path="profile" element={<Profile />} />
        <Route path="/find-ticket" element={<FindTicket />} />

        <Route path="events" element={<Events />} />
        <Route path="event/:slug" element={<EventPage />} />
        <Route path="eventPage" element={<EventPage />} />
        <Route path="report-problem" element={<ReportProblem />} />

        <Route
          path="buyTicket"
          element={<BuyTicket stripePromise={stripePromise} />}
        />
        <Route
          path="congratulations"
          element={<Congratulations stripePromise={stripePromise} />}
        />

        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="/page/:info" element={<InfoPage />} />

        <Route path="admin" element={<AdminPanel />} />
        <Route path="admin/eventRequest/:id" element={<RequestsDesktop />} />
        <Route path="admin/addSponsor" element={<AddSponsor />} />
        <Route path="admin/sponsorTemplate" element={<SponsorTemplate />} />

        <Route path="/ticket" element={<TicketDoc />} />

        <Route path="promoter/*" element={<OrganizerPanel />} />

        <Route path="/barcode" element={<BarcodeScanner />} />

        {/* Counter */}

        <Route path="/scanner" element={<Barcode />} />
        <Route path="/unsubscribe/:email" element={<Unsubscribe />} />
        <Route
          path="/seller"
          element={<HotSale stripePromise={stripePromise} />}
        />
      </Route>
    )
  );

  useEffect(() => {
    localeInit();
    stripeInit();
    console.log(navigator.userAgent);
    window.onerror = function (message, source, lineno, colno, error) {
      console.error("Error:", message);
      console.error("Source:", source);
      console.error("Line Number:", lineno);
      console.error("Column Number:", colno);
      console.error("Error Object:", error);

      return true;
    };
  }, []);
  return (
    <>
      <ErrorBoundary>
        <CssBaseline />
        <RouterProvider router={router} />
        <ToastContainer
          position="bottom-left"
          autoClose={4000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </ErrorBoundary>
    </>
  );
};

export default App;
