import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        'HOME': 'HOME',
        'EVENTS': 'EVENTS',
        'CLOSE': 'CLOSE',
        'LOGOUT': 'LOGOUT',
        'Logout': 'Logout',
        'Login': 'Login',
        'Password': 'Password',
        'E-mail': 'E-mail',
        'Confirm Password': 'Confirm Password',
        'Submit': 'Submit',
        'Save Password': 'Save Password',
        'Forgot your password?': 'Forgot your password?',
        'Back to login': 'Back to login',
        'Sign up': 'Sign up',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Phone Number': 'Phone Number',
        'Tax Number': 'Tax Number',
        'I am an organizator': 'I am an organizator',
        'By clicking Sign Up, you agree to our Terms, Privacy Policy and Cookies Policy': 'By clicking Sign Up, you agree to our Terms, Privacy Policy and Cookies Policy',
        'Search Event': 'Search Event',
        'Search': 'Search',
        'My Account': 'My Account',
        'Profile': 'Profile',
        'Birth Date': 'Birth Date',
        'Edit': 'Edit',
        'Safety': 'Safety',
        'My Tickets': 'My Tickets',
        'find_in_your_country': 'Find events in your country:',
        'COUNTRY': 'COUNTRY',
        'Event Details': 'Event Details',
        'Buy Ticket': 'Buy Ticket',
        'Tickets': 'Tickets',
        "There are tickets in your cart": "There are {{amount}} tickets in your cart",
        'Block': 'Block',
        'Row': 'Row',
        'Seat': 'Seat',
        'Ticket Cost': 'Ticket Cost',
        'Service Cost': 'Service Cost',
        'Total Cost': 'Total Cost',
        'Remove': 'Remove',
        'Remove All': 'Remove All',
        'Add Seat': 'Edit Seat',
        'Continue as Guest': 'Continue as Guest',
        'Guest User Informations': 'Guest User Informations',
        'I want bill': 'I want a bill',
        'Country': 'Country',
        'City': 'City',
        'Zip Code': 'Zip Code',
        'Address': 'Address',
        'Address continuation': 'Address continuation',
        'Payment': 'Payment',
        'Legal Notices': 'Legal Notices',
        'PRELIMINARY INFORMATION FORM': 'PRELIMINARY INFORMATION FORM',
        'Distance Selling Contract': 'Distance Selling Contract',
        'Return Policy': 'Return Policy',
        'Service': 'Service',
        'Total': 'Total',
        'Privacy Policy': 'Privacy Policy ',
        'Cookie Policy': 'Cookie Policy',
        'Terms': 'Terms, ',
        'by checking start': 'By checking, you agree to our ',
        'and': 'and ',
        'by checking end': '',
        'privacy_policy': 'Privacy Policy',
        'preliminary_info': 'Preliminary Information Form',
        'distance_selling_agreement': 'Distance Sales Agreement',
        'customer_agreement': 'User Agreement',
        'return_policy': 'Cancellation and Refund Policy',
        'cookie_policy': 'Cookie Policy',
        'pdpl': 'Personal Data Protection Law Disclosure',
        'pdpl_form': 'PDPL Application Form',
        'pdpl_concent_form': 'PDPL Consent Form',
        'about_us': 'About Us',
        'customer_login': 'Customer Sign In',
        'customer_profile': 'Customer Profile',
        'promoter_login': 'Promoter Sign In',
        'contact_mail': 'Contact Us',
        'legal_area': 'Legal Area',
        'site_map': 'Site Map',
        'all_events': 'All Events',
        'Buy': 'Buy',
        'not_found': 'No result found.',
        'Rest Amount': 'Rest:',
        'Reservation Expires In:': 'Reservation Expires In:',
        'vat_included': 'VAT included',
        'for_fun': 'FOR FUN',
        'CONCERT': 'CONCERTS',
        'Zoom In': 'Zoom In',
        'Zoom Out': 'Zoom Out',
        'cart': 'Cart',
        'total': 'Total',
        'reserved': 'Reserved',
        'reservation_expired': 'Your reservation expired.',
        'error_try_again': 'Error occured. Please try again',
        'location_updated': 'Location updated successfully',
        'event_updated': 'Event updated successfully',
        'event_added': 'Event added successfully',
        'event_deleted': 'Event deleted successfully',
        'customer_deleted': 'Customer deleted successfully',
        'customer_added': 'Customer added successfully',
        'promoter_confirmed': 'Promoter successfully confirmed',
        'commission_is_set': 'Event commission defined successfully',
        'payment_updated': 'Payment updated',
        'payment_added': 'Payment created',
        'payment_deleted': 'Payment deleted',
        'promoter_added': 'Promoter added',
        'promoter_updated': 'Promoter updated',
        'promoter_confirmed': 'Promoter confirmed',
        'promoter_deleted': 'Promoter deleted',
        'ticket_canceled': 'Ticket canceled',
        'tickets_sent_again': 'Tickets sent to the customer.',
        'location_added': 'Location added',
        'event_added': 'Event added',
        'sure_deleting_counter': 'Deleting counter, are you sure?',
        'account_deleted': 'Hesap silindi',
        'default_ticket_type_delete_error': 'Default type cannot be deleted',
        'profile_updated': 'Profile Updated Successfuly',
        'password_reset_success': 'Password has been successfully reset',
        'welcome_back': 'Welcome back, ',
        'account_created': 'Account created successfully',
        'reset_email_sent': 'Password reset mail successfully sent to your email.',
        'password_reset_success': 'Your password has been reset successfully.',
        'ticket_date': 'Date: ',
        'ticket_day': 'Day: ',
        'ticket_time': 'Time: ',
        'ticket_block': 'Block: ',
        'ticket_row': 'Row: ',
        'ticket_seat': 'Seat: ',
        'ticket_promoter': 'Promoter: ',
        'ticket_dooropen': 'Doors Open: ',
        'ticket_price': 'Price: ',
        'event_ended': 'This event has ended',
        'email_is_used': 'This email address is used already.',
        'phone_is_used': 'This phone number is used already',
        'Sunday': 'Sunday',
        'Monday': 'Monday',
        'Tuesday': 'Tuesday',
        'Wednesday': 'Wednesday',
        'Thursday': 'Thursday',
        'Friday': 'Friday',
        'Saturday': 'Saturday',
        'Business Name': 'Company Name',
        'SoldOut': 'Sold Out',
        'planScheme': 'Plan Scheme',
        'planImage': 'Plan Image',
        'sorry_taken': 'We are sorry, these seats have just been booked',
        'order_complete_not_verified': " order is complete. \n Once your payment is approved, we'll send your tickets as a PDF to your email. This usually takes about 5 minutes. \nYou can also find your approved tickets on your account profile page. \nEnjoy the event!",
        'go_to_tickets': "Profile",
        'see_other_events': "Checkout New Events",
        'congrats': 'Congratulations',
        "Find Your Ticket": "Find Your Ticket",
        "Find": "Find",
        "Report a Problem": "Report a Problem",
        "Your full name": "Your full name",
        "Your email address": "Your email address",
        "Your phone number": "Your phone number",
        "Payment Problem": "Payment Problem",
        "Ticket Delivery": "Ticket Delivery",
        "Other": "Other",
        "Description": "Description",
        "Choose": "Choose",
        "order_canceled": "Order canceled",
        "email_phone_empty": "Please enter your email and phone number!",
        "no_tickets_found": "Ticket not found.",
        "message_delivered": "Your message has been delivered.",
        "fill_inputs": "Please fill in the fields.",
      }
    },
    tr: {
      translations: {
        'HOME': 'ANASAYFA',
        'EVENTS': 'ETKİNLİKLER',
        'LOGOUT': 'ÇIKIŞ',
        'Logout': 'Çıkış',
        'CLOSE': 'KAPAT',
        'Login': 'Giriş Yap',
        'Password': 'Şifre',
        'E-mail': 'E-mail',
        'Confirm Password': 'Şifre Tekrar',
        'Submit': 'Gönder',
        'Save Password': 'Şifreyi Kaydet',
        'Forgot your password?': 'Şifreni mi unuttun?',
        'Back to login': 'Girişe geri dön',
        'Sign up': 'Hesap Oluştur',
        'First Name': 'Ad',
        'Last Name': 'Soyad',
        'Phone Number': 'Telefon Numarası',
        'Tax Number': 'Vergi Numarası',
        'I am an organizator': 'Ben bir organizatörüm',
        'Search Event': 'Etkinlik Ara',
        'Search': 'Ara',
        'My Account': 'Hesabım',
        'Profile': 'Profil',
        'Birth Date': 'Doğum Tarihi',
        'Edit': 'Düzenle',
        'Safety': 'Güvenlik',
        'My Tickets': 'Biletlerim',
        'COUNTRY': 'ÜLKE',
        'Event Details': 'Etkinlik Detayı',
        'Buy Ticket': 'Bilet Al',
        'Tickets': 'Biletler',
        "There are tickets in your cart": "Sepetinizde {{amount}} adet bilet bulunmaktadır",
        'Block': 'Blok',
        'Row': 'Sıra',
        'Seat': 'Koltuk',
        'Ticket Cost': 'Bilet Bedeli',
        'Service Cost': 'Servis Bedeli',
        'Total Cost': 'Toplam',
        'Remove': 'Sil',
        'Remove All': 'Tümünü Sil',
        'Add Seat': 'Koltuk Düzenle',
        'Continue as Guest': 'Misafir olarak devam et',
        'Guest User Informations': 'Misafir Kullanıcı Bilgileri',
        'I want bill': 'Fatura İstiyorum',
        'Country': 'Ülke',
        'City': 'Şehir',
        'Zip Code': 'Posta Kodu',
        'Address': 'Adres',
        'Address continuation': 'Adres devamı',
        'Payment': 'Ödeme',
        'Legal Notices': 'Yasal Bildirimler',
        'PRELIMINARY INFORMATION FORM': 'ÖN BİLGİLENDİRME FORMU',
        'Distance Selling Contract': 'Mesafeli Satış Sözleşmesi',
        'Return Policy': 'İptal ve İade Politikası',
        'Service': 'Servis',
        'Total': 'Toplam',
        'Privacy Policy': 'Gizlilik Politikamızı',
        'Cookie Policy': 'Çerez Politikamızı ',
        'Terms': 'Kullanıcı Sözleşmemizi, ',
        'by checking start': 'Onay kutusuna tıklayarak ',
        'and': 've ',
        'by checking end': 'kabul etmiş olursunuz.',
        'Zoom In': 'Yaklaş',
        'Zoom Out': 'Uzaklaş',
        'buy': 'al',
        'privacy_policy': 'Gizlilik Politikası',
        'preliminary_info': 'Ön Bilgilendirme Formu',
        'distance_selling_agreement': 'Mesafeli Satış Sözleşmesi',
        'customer_agreement': 'Kullanıcı Sözleşmesi',
        'return_policy': 'İptal ve İade Politikası',
        'cookie_policy': 'Çerez Politikası',
        'pdpl': 'KVKK Aydınlatma Metni',
        'pdpl_form': 'KVKK Başvuru Formu',
        'pdpl_concent_form': 'KVKK Rıza Formu',
        'about_us': 'Hakkımızda',
        'customer_login': 'Kullanıcı Girişi',
        'customer_profile': 'Kullanıcı Bilgileri',
        'promoter_login': 'Organizatör Girişi',
        'contact_mail': 'İletişim',
        'legal_area': 'Hukuki Alan',
        'site_map': 'Site Haritası',
        'all_events': 'Tüm Etkinlikler',
        'Buy': 'Al',
        'not_found': 'Sonuç bulunamadı.',
        'Rest Amount': 'Kalan:',
        'Reservation Expires In:': 'Kalan zaman:',
        'vat_included': 'KDV Dahil',
        'for_fun': 'EĞLENCE İÇİN',
        'CONCERT': 'KONSER',
        'find_in_your_country': 'Ülkenizdeki etkinlikleri bulun:',
        'cart': 'Sepet',
        'total': 'Toplam',
        'reserved': 'Alındı',
        'reservation_expired': "Rezervasyonunuz sona erdi.",
        'error_try_again': 'Hata oluştu. Lütfen tekrar deneyin',
        'location_updated': "Konum başarıyla güncellendi",
        'event_updated': "Etkinlik başarıyla güncellendi",
        'event_added': "Etkinlik başarıyla eklendi",
        'event_deleted': "Etkinlik başarıyla silindi",
        'customer_deleted': "Müşteri başarıyla silindi",
        'customer_added': "Müşteri başarıyla eklendi",
        'promoter_confirmed': "Promosyoncu başarıyla onaylandı",
        'commission_is_set': 'Etkinlik komisyonu başarıyla tanımlandı',
        'payment_updated': "Ödeme güncellendi",
        'payment_added': "Ödeme oluşturuldu",
        'payment_deleted': "Ödeme silindi",
        'promoter_added': "Organizatör eklendi",
        'promoter_updated': "Organizatör güncellendi",
        'promoter_confirmed': 'Organizatör hesabı onaylandı',
        'promoter_deleted': "Organizatör silindi",
        'ticket_canceled': 'Bilet iptal',
        'tickets_sent_again': "Müşteriye gönderilen biletler.",
        'location_added': "Konum eklendi",
        'event_added': "Etkinlik eklendi",
        'sure_deleting_counter': "Sayaç siliniyor, emin misiniz?",
        'account_deleted': 'Hesap silindi',
        'default_ticket_type_delete_error': "Varsayılan tür silinemez",
        'profile_updated': "Profil Başarıyla Güncellendi",
        'password_reset_success': 'Şifre başarıyla sıfırlandı',
        'welcome_back': 'Tekrar hoşgeldiniz, ',
        'account_created': 'Hesap başarıyla oluşturuldu',
        'reset_email_sent': 'Parola sıfırlama postası başarıyla e-postanıza gönderildi.',
        'password_reset_success': 'Parolanız başarıyla sıfırlandı.',
        'ticket_date': 'Tarih: ',
        'ticket_day': 'Gün: ',
        'ticket_time': 'Saat: ',
        'ticket_block': 'Blok: ',
        'ticket_row': 'Sıra: ',
        'ticket_seat': 'Koltuk: ',
        'ticket_promoter': 'Organizatör: ',
        'ticket_dooropen': 'Kapı Açılır: ',
        'ticket_price': 'Fiyat: ',
        'event_ended': 'Bu etkinlik sona erdi',
        'email_is_used': 'Bu e-posta adresi zaten kullanılıyor',
        'phone_is_used': 'Bu telefon numarası zaten kullanılıyor',
        'Sunday': 'Pazar',
        'Monday': 'Pazartesi',
        'Tuesday': 'Salı',
        'Wednesday': 'Çarşamba',
        'Thursday': 'Perşembe',
        'Friday': 'Cuma',
        'Saturday': 'Cumartesi',
        'Business Name': 'Şirket Adı',
        'SoldOut': 'Tükendi',
        'planScheme': 'Plan Şeması',
        'planImage': 'Plan Resmi',
        'sorry_taken': 'Üzgünüz, bu koltuklar yeni rezerve edildi',
        'order_complete_not_verified': " sipariş tamamlandı. \n Ödemeniz onaylandıktan sonra biletlerinizi PDF olarak e-posta adresinize göndereceğiz. Bu genellikle yaklaşık 5 dakika sürer. \nOnaylanan biletleri hesap profili sayfanızda da bulabilirsiniz. .\nEtkinliğin tadını çıkarın!",
        'go_to_tickets': "Biletlerime Git",
        'see_other_events': "Yeni Etkinlik Bul",
        'congrats': 'Tebrikler',
        "Find Your Ticket": 'Biletini Bul',
        "Find": "Bul",
        "Report a Problem": "Problem Bildir",
        "Your full name": "Ad Soyad",
        "Your email address": "Mail adresi",
        "Your phone number": "Telefon numarası",
        "Payment Problem": "Ödeme Problemi",
        "Ticket Delivery": "Bilet Teslimatı",
        "Other": "Diğer",
        "Description": "Açıklama",
        "Choose": "Seçin",
        "order_canceled": "Sipariş iptal edildi",
        "email_phone_empty": "Lütfen email ve telefon numaranızı girin!",
        "no_tickets_found": "Bilet bulunamadı.",
        "message_delivered": "Mesajınız iletildi.",
        "fill_inputs": "Lütfen alanları doldurun",
      }
    },
    de: {
      translations: {
        'HOME': 'STARTSEITE',
        'EVENTS': 'GESCHEHEN',
        'LOGOUT': 'AUSLOGGEN',
        'Logout': 'Ausloggen',
        'CLOSE': 'SCHLIESSEN',
        'Login': 'Anmelden',
        'Password': 'Passwort',
        'E-mail': 'E-mail',
        'Confirm Password': 'Passwort Bestätigen',
        'Submit': 'Absenden',
        'Save Password': 'Passwort Speichern',
        'Forgot your password?': 'Passwort vergessen?',
        'Back to login': 'Zurück zur Anmelden',
        'Sign up': 'Anwerben',
        'First Name': 'Vorname',
        'Last Name': 'Nachname',
        'Phone Number': 'Telefonnummer',
        'Tax Number': 'Steuernummer',
        'I am an organizator': 'Ich bin Organisator',
        'By clicking Sign Up, you agree to our Terms, Privacy Policy and Cookies Policy': 'Indem Sie auf „Registrieren“ klicken, stimmen Sie unseren Nutzungsbedingungen, Datenschutzrichtlinien und Cookie-Richtlinien zu',
        'Search Event': 'Ereignis suchen',
        'Search': 'Suche',
        'My Account': 'Mein Konto',
        'Profile': 'Profil',
        'Birth Date': 'Geburtsdatum',
        'Edit': 'Bearbeiten',
        'Safety': 'Sicherheit',
        'My Tickets': 'Meine Tickets',

        'COUNTRY': 'LAND',
        'Event Details': 'Veranstaltungsdetails',
        'Buy Ticket': 'Ticket kaufen',
        'Tickets': 'Eintrittskarten',
        "There are tickets in your cart": "Es befinden sich {{amount}} Tickets in Ihrem Warenkorb",
        'Block': 'Block',
        'Row': 'Zeile',
        'Seat': 'Sitz',
        'Ticket Cost': 'Ticketkosten',
        'Service Cost': 'Servicekosten',
        'Total Cost': 'Gesamtkosten',
        'Remove': 'Entfernen',
        'Remove All': 'Alles entfernen',
        'Add Seat': 'Platz hinzufügen',
        'Continue as Guest': 'als Gast fortfahren',
        'Guest User Informations': 'Gastbenutzerinformationen',
        'I want bill': 'Ich will Rechnung',
        'Country': 'Land',
        'City': 'Stadt',
        'Zip Code': 'Postleitzahl',
        'Address': 'Adresse',
        'Address continuation': 'Adressfortsetzung',
        'Payment': 'Zahlung',
        'Legal Notices': 'Rechtliche Hinweise',
        'PRELIMINARY INFORMATION FORM': 'VORLÄUFIGE INFORMATIONEN FORMULAR',
        'Distance Selling Contract': 'Fernabsatzvertrag',
        'Return Policy': 'Rücknahmegarantie',
        'Service': 'Service',
        'Total': 'Gesamt',
        'Privacy Policy': 'Datenschutzrichtlinien ',
        'Cookie Policy': 'Cookie-Richtlinien ',
        'Terms': 'Nutzungsbedingungen, ',
        'by checking start': 'Indem Sie auf „Registrieren“ klicken, stimmen Sie unseren ',
        'and': 'und ',
        'by checking end': 'zu',
        'privacy_policy': 'Datenschutz-Bestimmungen',
        'preliminary_info': 'Vorläufiges Informationsformular',
        'distance_selling_agreement': 'Fernabsatzvertrag',
        'customer_agreement': 'Nutzungsbedingungen',
        'return_policy': 'Stornierungs- und Rückerstattungsrichtlinie',
        'cookie_policy': 'Cookie-Richtlinie',
        'pdpl': 'Klarstellungstext des Gesetzes zum Schutz personenbezogener Daten',
        'pdpl_form': 'Antragsformular zum Schutz personenbezogener Daten',
        'pdpl_concent_form': 'Einwilligungsformular zum Schutz personenbezogener Daten',
        'about_us': 'Über Uns',
        'customer_login': 'Benutzer-Anmeldung',
        'customer_profile': 'Informationen für Benutzer',
        'promoter_login': 'Veranstalter-Login',
        'contact_mail': 'Kontakt',
        'legal_area': 'Rechtsinformation',
        'site_map': 'Karte der Website',
        'all_events': 'Alle Veranstaltungen',
        'Buy': 'Kauf',
        'not_found': 'Keine Einträge gefunden.',
        'Rest Amount': 'Rest:',
        'Reservation Expires In:': 'Reservierung läuft ab in:',
        'vat_included': 'inkl. MwSt',
        'for_fun': 'ZUM SPASS:',
        'CONCERT': 'KONZERTE',
        'find_in_your_country': 'Finden Sie Veranstaltungen in Ihrem Land:',
        'Zoom In': 'Hineinzoomen',
        'Zoom Out': 'Rauszoomen',
        'cart': 'Korb',
        'total': 'Gesamt',
        'reserved': 'Reserviert',
        'reservation_expired': 'Ihre Reservierung ist abgelaufen.',
        'error_try_again': 'Ein Fehler ist aufgetreten. Bitte versuche es erneut',
        'location_updated': 'Standort erfolgreich aktualisiert',
        'event_updated': 'Ereignis erfolgreich aktualisiert',
        'event_added': 'Ereignis erfolgreich hinzugefügt',
        'event_deleted': 'Ereignis erfolgreich gelöscht',
        'customer_deleted': 'Kunde erfolgreich gelöscht',
        'customer_added': 'Kunde erfolgreich hinzugefügt',
        'promoter_confirmed': 'Promoter erfolgreich bestätigt',
        'commission_is_set': 'Event-Provision erfolgreich definiert',
        'payment_updated': 'Zahlung aktualisiert',
        'payment_added': 'Zahlung erstellt',
        'payment_deleted': 'Zahlung gelöscht',
        'promoter_added': 'Promoter hinzugefügt',
        'promoter_updated': 'Veranstalter aktualisiert',
        'promoter_confirmed': 'Veranstalterkonto bestätigt',
        'promoter_deleted': 'Veranstalter gelöscht',
        'ticket_canceled': 'Ticket storniert',
        'tickets_sent_again': 'Tickets an den Kunden gesendet.',
        'location_added': 'Standort hinzugefügt',
        'event_added': 'Ereignis hinzugefügt',
        'sure_deleting_counter': 'Zähler löschen, sind Sie sicher?',
        'account_deleted': 'Hesap silindi',
        'default_ticket_type_delete_error': 'Standardtyp kann nicht gelöscht werden',
        'profile_updated': 'Profil erfolgreich aktualisiert',
        'password_reset_success': 'Passwort wurde erfolgreich zurückgesetzt',
        'welcome_back': 'Willkommen zurück, ',
        'account_created': 'Konto erfolgreich erstellt',
        'reset_email_sent': 'Mail zum Zurücksetzen des Passworts erfolgreich an Ihre E-Mail-Adresse gesendet.',
        'password_reset_success': 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
        'ticket_date': 'Datum: ',
        'ticket_day': 'Tag: ',
        'ticket_time': 'Zeit: ',
        'ticket_block': 'Block: ',
        'ticket_row': 'Reihe: ',
        'ticket_seat': 'Sitz: ',
        'ticket_promoter': 'Veranstalter: ',
        'ticket_dooropen': 'Türen öffnen sich: ',
        'ticket_price': 'Preis: ',
        'ticket_pricez': 'Preis: ',
        'event_ended': 'Diese Veranstaltung ist beendet',
        'email_is_used': 'Diese E-Mail-Adresse wird bereits verwendet',
        'phone_is_used': 'Diese Telefonnummer wird bereits verwendet',
        'Sunday': 'Sonntag',
        'Monday': 'Montag',
        'Tuesday': 'Dienstag',
        'Wednesday': 'Mittwoch',
        'Thursday': 'Donnerstag',
        'Friday': 'Freitag',
        'Saturday': 'Samstag',
        'Business Name': 'Name des Unternehmens',
        'SoldOut': 'Ausverkauft',
        'planScheme': 'Planschema',
        'planImage': 'Bild des Plans',
        'sorry_taken': 'Es tut uns leid, diese Plätze wurden gerade erst gebucht',
        'order_complete_not_verified': "Die Bestellung ist abgeschlossen. \n Sobald Ihre Zahlung genehmigt wurde, senden wir Ihre Tickets als PDF an Ihre E-Mail. Dies dauert normalerweise etwa 5 Minuten. \nSie können Ihre genehmigten Tickets auch auf Ihrer Kontoprofilseite finden . \nViel Spaß beim Event!",
        'go_to_tickets': "Profil",
        'see_other_events': "Neue Veranstaltung finden",
        'congrats': 'Glückwunsch',
        "Find Your Ticket": "Finden Sie Ihr Ticket",
        "Find": "Finden",
        "Report a Problem": "Ein Problem melden",
        "Your full name": "Ihr vollständiger Name",
        "Your email address": "Ihre E-Mail-Adresse",
        "Your phone number": "Deine telefonnummer",
        "Payment Problem": "Zahlungsproblem",
        "Ticket Delivery": "Ticketlieferung",
        "Other": "Andere",
        "Description": "Beschreibung",
        "Choose": "Wählen",
        "order_canceled": "Bestellung storniert",
        "email_phone_empty": "Bitte geben Sie Ihre E-Mail-Adresse und Telefonnummer ein!",
        "no_tickets_found": "Kein Ticket gefunden.",
        "message_delivered": "Ihre Nachricht wurde übermittelt.",
        "fill_inputs": "Bitte füllen Sie die Felder aus.",
      }
    },
    ru: {
      translations: {
        'HOME': 'ГЛАВНАЯ',
        'EVENTS': 'СОБЫТИЯ',
        'LOGOUT': 'ВЫЙТИ',
        'Logout': 'Выйти',
        'CLOSE': 'ЗАКРЫТЬ',
        'Login': 'Авторизоваться',
        'Password': 'Пароль',
        'E-mail': 'Электронная почта',
        'Confirm Password': 'Подтвердите пароль',
        'Submit': 'Представлять на рассмотрение',
        'Save Password': 'Сохраните пароль',
        'Forgot your password?': 'Забыли пароль?',
        'Back to login': 'Вернуться на страницу авторизации',
        'Sign up': 'Зарегистрироваться',
        'First Name': 'Имя',
        'Last Name': 'Фамилия',
        'Phone Number': 'Номер телефона',
        'Tax Number': 'Налоговый номер',
        'I am an organizator': 'Я организатор',
        'By clicking Sign Up, you agree to our Terms, Privacy Policy and Cookies Policy': 'Нажимая «Зарегистрироваться», Terms, Privacy Policy and Cookies Policy',
        'Search Event': 'Поиск события',
        'Search': 'Поиск',
        'My Account': 'Мой аккаунт',
        'Profile': 'Профиль',
        'Birth Date': 'Дата рождения',
        'Edit': 'Редактировать',
        'Safety': 'Безопасность',
        'My Tickets': 'Мои билеты',

        'COUNTRY': 'СТРАНА',
        'Event Details': 'Детали события',
        'Buy Ticket': 'Купить билет',
        'Tickets': 'Билеты',
        "There are tickets in your cart": "В вашей корзине {{amount}} билетов",
        'Block': 'Блокировать',
        'Row': 'Ряд',
        'Seat': 'Сиденье',
        'Ticket Cost': 'Стоимость билета',
        'Service Cost': 'Стоимость услуг',
        'Total Cost': 'Общая стоимость',
        'Remove': 'Удалить',
        'Remove All': 'Убрать все',
        'Add Seat': 'Редактировать место',
        'Continue as Guest': 'Продолжить как гость',
        'Guest User Informations': 'Информация о гостевом пользователе',
        'I want bill': 'Я хочу счет',
        'Country': 'Страна',
        'City': 'Город',
        'Zip Code': 'Почтовый индекс',
        'Address': 'Адрес',
        'Address continuation': 'Продолжение адреса',
        'Payment': 'Оплата',
        'Legal Notices': 'Правовые уведомления',
        'PRELIMINARY INFORMATION FORM': 'ФОРМА ПРЕДВАРИТЕЛЬНОЙ ИНФОРМАЦИИ',
        'Distance Selling Contract': 'Договор о дистанционной продаже',
        'Return Policy': 'Политика возврата',
        'Service': 'Услуга',
        'Total': 'Всего',
        'Privacy Policy': 'Политика конфиденциальности ',
        'Cookie Policy': 'Политика в отношении файлов cookie',
        'Terms': 'Условия, ',
        'by checking start': 'Проверяя, вы соглашаетесь с нашими ',
        'and': 'и ',
        'by checking end': '',
        'privacy_policy': 'Политика Конфиденциальности',
        'preliminary_info': 'Форма Предварительной Информации',
        'distance_selling_agreement': 'Соглашение о Дистанционной Продаже',
        'customer_agreement': 'Пользовательское Соглашение',
        'return_policy': 'Политика Отмены и Возврата',
        'cookie_policy': 'Политика в Отношении Файлов Сookie',
        'pdpl': 'Пояснение Закона о Защите Персональных Данных',
        'pdpl_form': 'Форма Заявки по Закону о Защите Персональных Данных',
        'pdpl_concent_form': 'Форма согласия с Законом о Защите Персональных Данных',
        'about_us': 'О Нас',
        'customer_login': 'Вход Для Клиентов',
        'customer_profile': 'Профиль Клиента',
        'promoter_login': 'Вход Для Организаторов',
        'contact_mail': 'Контакт',
        'legal_area': 'Юридическая Информация',
        'site_map': 'Карта Сайта',
        'all_events': 'Все События',
        'Buy': 'Купи',
        'not_found': 'Результатов не найдено.',
        'Rest Amount': 'Осталось:',
        'Reservation Expires In:': 'Бронь истекает через:',
        'vat_included': 'с НДС',
        'for_fun': 'ДЛЯ РАЗВЛЕЧЕНИЯ:',
        'CONCERT': 'КОНЦЕРТЫ',
        'find_in_your_country': 'Найдите события в вашей стране:',
        'Zoom In': 'Приблизь',
        'Zoom Out': 'Отдали',
        'cart': 'Корзина',
        'total': 'Всего',
        'reserved': 'Занято',
        'reservation_expired': "Срок действия вашего бронирования истек",
        'error_try_again': "Произошла ошибка. Пожалуйста, попробуйте еще раз",
        'location_updated': "Местоположение успешно обновлено",
        'event_updated': "Событие успешно обновлено",
        'event_added': "Событие успешно добавлено",
        'event_deleted': "Событие успешно удалено",
        'customer_deleted': "Клиент успешно удален",
        'customer_added': "Клиент успешно добавлен",
        'promoter_confirmed': "Промоутер успешно подтвержден",
        'commission_is_set': "Комиссия мероприятия успешно определена",
        'payment_updated': "Платеж обновлен",
        'payment_added': "Платеж создан",
        'payment_deleted': "Платеж удален",
        'promoter_added': "Добавлен промоутер",
        'promoter_updated': "Промоутер обновлен",
        'promoter_confirmed': 'Аккаунт организатора подтвержден',
        'promoter_deleted': "Промоутер удален",
        'ticket_canceled': "Билет отменен",
        'tickets_sent_again': "Билеты отправлены заказчику»",
        'location_added': "Местоположение добавлено",
        'event_added': "Событие добавлено",
        'sure_deleting_counter': "Удаление счетчика, вы уверены?",
        'account_deleted': "Хесап силинди",
        'default_ticket_type_delete_error': "Тип по умолчанию не может быть удален",
        'profile_updated': "Профиль успешно обновлен",
        'password_reset_success': "Пароль успешно сброшен",
        'welcome_back': "Добро пожаловать, ",
        'account_created': "Учетная запись успешно создана",
        'reset_email_sent': "Письмо для сброса пароля успешно отправлено на вашу электронную почту»",
        'password_reset_success': "Ваш пароль был успешно сброшен",
        'ticket_date': 'Дата: ',
        'ticket_day': 'День: ',
        'ticket_time': 'Время: ',
        'ticket_block': 'Блок: ',
        'ticket_row': 'Ряд: ',
        'ticket_seat': 'Место: ',
        'ticket_promoter': 'Организатор: ',
        'ticket_dooropen': 'Двери Открываются: ',
        'ticket_price': 'Цена: ',
        'event_ended': 'Это событие закончилось',
        'email_is_used': 'Этот адрес электронной почты уже используется',
        'phone_is_used': 'Этот номер телефона уже используется',
        'Sunday': 'Воскресенье',
        'Monday': 'Понедельник',
        'Tuesday': 'Вторник',
        'Wednesday': 'Среда',
        'Thursday': 'Четверг',
        'Friday': 'Пятница',
        'Saturday': 'Суббота',
        'Business Name': 'Название Компании',
        'SoldOut': 'Распродано',
        'planScheme': 'Схема плана',
        'planImage': 'Изображение плана',
        'sorry_taken': 'Нам жаль, эти места только что забронировали',
        'order_complete_not_verified': " заказ выполнен. \n Как только ваш платеж будет одобрен, мы отправим ваши билеты в формате PDF на вашу электронную почту. Обычно это занимает около 5 минут. \nВы также можете найти одобренные билеты на странице профиля своей учетной записи. .\nНаслаждайтесь событием!",
        'go_to_tickets': "Мои Билеты",
        'see_other_events': 'Продолжить',
        'congrats': "Поздравляем",
        "Find Your Ticket": "Найдите свой билет",
        "Find": "Находить",
        "Report a Problem": "Сообщить о проблеме",
        "Your full name": "Ваше полное имя",
        "Your email address": "Ваш электронный адрес",
        "Your phone number": "Ваш номер телефона",
        "Payment Problem": "Проблема с оплатой",
        "Ticket Delivery": "Доставка билетов",
        "Other": "Другой",
        "Description": "Описание",
        "Choose": "Выбирать",
        "order_canceled": "Заказ отменен",
        "email_phone_empty": "Пожалуйста, введите вашу электронную почту и номер телефона!",
        "no_tickets_found": "Билет не найден.",
        "message_delivered": "Ваше сообщение доставлено.",
        "fill_inputs": "Пожалуйста, заполните поля.",
      }
    },
  },
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
  react: {
    wait: true
  }
});

export default i18n;
