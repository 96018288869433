import { useTranslation } from "react-i18next"
import TopBar from "../AppBar/TopBar"
import Footer from "../Panel/Footer/Footer"
import { useState } from "react"
import { fetchTicketsByPhoneAndEmail } from "../../Data/ticket"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import logo from "../../Assets/Images/logo.png";
import { toast } from "react-toastify"
import HCaptcha from '@hcaptcha/react-hcaptcha';

const FindTicket = () => {

    const CDN_URL = process.env.REACT_APP_CDN_URL;
    const { t } = useTranslation()
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [tickets, setTickets] = useState([])
    const [tab, setTab] = useState('search')
    const [expanded, setExpanded] = useState({ tickets: false });
    const [isDisabled, setIsDisabled] = useState(true)


    const onClickFind = async () => {
        if (phone && email) {
            try {
                const tickets = await fetchTicketsByPhoneAndEmail({ phone, email })
                if (tickets.length > 0) {
                    setTickets(tickets)
                    setTab('result')
                } else {
                    toast.error(t('no_tickets_found'))
                }
                console.log(tickets)
            } catch (err) {

            }
        } else {
            toast.error(t('email_phone_empty'))
        }
    }

    const onVerify = (token) => {
        //console.log('hCaptcha token', token);
        setIsDisabled(false)
    };

    return (
        <div className="relative" style={{ height: 'screen' }}>
            <TopBar color="#ffd283" />
            {
                tab === 'search' &&
                <div className="container d-flex justify-content-center" style={{ marginTop: '100px' }}>
                    <div>
                        <div className="text-center mb-3">
                            {t('Find Your Ticket')}
                        </div>
                        <div className="mb-3">
                            <input
                                className="w-100"
                                placeholder={t('Your phone number')}
                                onChange={e => setPhone(e.target.value)}
                                value={phone}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                className="w-100"
                                placeholder={t('Your email address')}
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                            />
                        </div>
                        <HCaptcha sitekey="71b6f6e1-b938-4173-a099-d2def0141b3e" onVerify={onVerify} hl={localStorage.getItem('e-ticket-al language').toLowerCase()} />
                        <div className="text-center">
                            <button onClick={onClickFind} disabled={isDisabled}>
                                {t('Find')}
                            </button>
                        </div>
                    </div>

                </div>
            }
            {
                tab === 'result' &&
                <div className="container d-flex justify-content-center" style={{ marginTop: '100px' }}>
                    <div>
                        <div className="mb-3">
                            {t('My Tickets')}
                            <Box>
                                <Accordion
                                    expanded={expanded.tickets}



                                    elevation={0}
                                    sx={{ borderBottom: "2px solid #f2c981" }}
                                >
                                    <AccordionSummary
                                        onClick={() => { setExpanded({ ...expanded, tickets: !expanded.tickets }) }}
                                        expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ paddingLeft: "0" }}
                                    >
                                        <Typography color="#df0c62" variant="h6">
                                            {t('My Tickets')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            {tickets?.map((item, index) => (
                                                <Grid item xs={12} sx={item.canceled ? { marginTop: '30px', pointerEvents: 'none', opacity: 0.5 } : { marginTop: '30px' }}>
                                                    <a style={{ color: '#df0c62', display: 'block' }}
                                                        target="_blank"
                                                        href={CDN_URL + 'pdfs/' + item._id + ".pdf"}>Indir</a>
                                                    <Box className={"celebTicket"}>
                                                        <Stack
                                                            display="flex"
                                                            direction="column"
                                                            justifyContent="center"
                                                            alignItems="start"
                                                            height="100%"
                                                            padding={{ xs: "0 7%", md: "0 25%" }}
                                                        >
                                                            <Typography
                                                                variant="h5"
                                                                fontWeight="bold"
                                                                textAlign="center"
                                                                color="#df0c62"
                                                                sx={{ textDecoration: "underline" }}
                                                            >
                                                                {item.event.title}
                                                            </Typography>

                                                            <Typography color="#df0c62">{item.event.location?.name}</Typography>
                                                            <br />
                                                            <Typography
                                                                variant="h6"
                                                                color="#df0c62"
                                                                fontWeight="bold"
                                                            >
                                                                {item?.event?.plan?.startsFromOne ? item?.trueLabel : item?.label} {item?.row ? (item.row + "/" + (item?.event?.plan?.startsFromOne ? item?.trueSeat : item?.seat)) : null}
                                                            </Typography>
                                                            <br />
                                                            <div className="row w-100">
                                                                <div className="col-6">
                                                                    <Typography color="#df0c62">{t('ticket_date')}</Typography>
                                                                    <Typography color="#df0c62">{item?.event?.doorOpenDate?.split('T')[0]}</Typography>
                                                                </div>
                                                                <div className="col-6">
                                                                    <Typography color="#df0c62">{t('ticket_dooropen')}</Typography>
                                                                    <Typography color="#df0c62">{item?.event?.doorOpenDate?.split('T')[1]}</Typography>
                                                                </div>
                                                            </div>



                                                            <br />
                                                            <img src={logo} alt="logo" width="50%" />
                                                        </Stack>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </AccordionDetails>

                                </Accordion>
                            </Box>
                        </div>
                    </div>
                </div>
            }

            {/* <div style={{ bottom: 0, width: '100%' }}>
                <Footer />
            </div  > */}

        </div>
    )
}

export default FindTicket