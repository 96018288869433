import {
  Box,
  Button,
  Container,
  Stack,
  Modal,
  Typography,
  Accordion,
  Grid,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Footer from "../../../Footer/Footer";
import NavBar from "../../../NavBar/NavBar";
import SearchBar from "../../../SearchBar/SearchBar";
import EventSubmit from "../../EventSubmit";
import LocationSubmit from "../../LocationSubmit";
import Charts from "../../../Stats/Charts";
import {
  Add,
  VisibilityOutlined,
  ExpandMore,
  FileDownloadOutlined,
  Close,
  Times,
} from "@mui/icons-material";
import { ReactComponent as City } from "../../../../../Assets/svg/city.svg";
import React, { useState, useEffect } from "react";
import classes from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import CurrentEvent from "./Expanding";
import { useMediaQuery } from "react-responsive";
import FooterTablet from "../../../Footer/Tablet/FooterTablet";
import FooterMobile from "../../../Footer/Mobile/FooterMobile";
import MobileNavBar from "../../../../AppBar/Mobile/NavBar/MobileNavBar";
import cookies from "react-cookies";
import { toast } from "react-toastify";
import {
  addEvent,
  listMyPromoted,
  updateEvent,
} from "../../../../../Data/event";
import { saveImage } from "../../../../../Data/general";
import { getDashboard } from "../../../../../Data/promoter";
import {
  standartSeats,
  defaultType,
  standartMap,
  showDiscountPrice,
} from "../../../../../Services/helpers";

import { useTranslation } from "react-i18next";
import InfosPdf from "../../../AdminPanel/Organizer/Desktop/OrganizerInfoPdf";

const Desktop = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [eventmodal, setEventModal] = useState(false);
  const [fieldmodal, setFieldModal] = useState(false);

  const [eventForm, setEventForm] = useState({
    plan: {
      simple: true,
      map: standartMap,
      standartSeatTypes: [...standartSeats],
    },
  });
  const [fieldForm, setFieldForm] = useState({});

  const [dashboard, setDashboard] = useState(null);
  const [promoted, setPromoted] = useState([]);
  const [endedEvents, setEndedEvents] = useState([]);
  const [notEndedEvents, setNotEndedEvents] = useState([]);
  const [activeEvent, setActiveEvent] = useState(null);
  const [pendingApiCall, setPendingApiCall] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 576px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });

  const cookiesConfig = {
    maxAge: process.env.REACT_APP_COOKIE_MAX_AGE,
    path: process.env.REACT_APP_BASE_URL,
  };

  const fetch = async () => {
    let allData = await getDashboard();
    setEndedEvents(allData.events.filter((it) => it.isEnded));
    setNotEndedEvents(allData.events.filter((it) => !it.isEnded));
    allData.events = null;
    setDashboard(allData);
    setPromoted(await listMyPromoted());
  };

  const onEventClose = () => {
    setEventModal(false);
  };

  const editEvent = (eve) => {
    setEventForm(eve);
    setEventModal(true);
  };

  const toggleEve = (ind) => {
    let evesList = [...promoted];
    let thiseveactive = evesList[ind].active;
    evesList.map((it, ind) => (evesList[ind].active = false));
    if (!thiseveactive) evesList[ind].active = true;
    // debugger;
    setPromoted(evesList);
  };

  const clearPromoterData = async () => {
    await cookies.remove("promoterFirstName", cookiesConfig);
    await cookies.remove("promoterLastName", cookiesConfig);
    await cookies.remove("promoterEmail", cookiesConfig);
    await cookies.remove("promoterPhoneNumber", cookiesConfig);
    await localStorage.removeItem("promoter");
  };

  const logout = () => {
    clearPromoterData().then(() => {
      navigate("/");
    });
  };

  const uploadImages = async () => {
    let imgs = { first: null, second: null };
    let url = "",
      smurl = "";

    if (eventForm.image) {
      if (typeof eventForm.image == "string") url = eventForm.image;
      else
        await saveImage(eventForm.image, "portrait").then((imgUrl) => {
          if (imgUrl) url = imgUrl;
        });
    } else url = "";

    if (eventForm.smimage) {
      if (typeof eventForm.smimage == "string") smurl = eventForm.smimage;
      else
        await saveImage(eventForm.smimage).then((imgUrl) => {
          if (imgUrl) smurl = imgUrl;
        });
    } else smurl = "";

    imgs = { first: url, second: smurl };
    return imgs;
  };

  const onEventSubmit = async () => {
    setPendingApiCall(true);
    await uploadImages().then(async (imgs) => {
      let _event = { ...eventForm };
      if (imgs.first) _event.image = imgs.first;
      if (imgs.second) _event.smimage = imgs.second;

      if (_event._id) {
        await updateEvent(_event).then((__event) => {
          if (!__event) {
            setPendingApiCall(false);
            toast.error(t("error_try_again"));
          } else {
            setPendingApiCall(false);
            setEventModal(false);
            setEventForm({});
            fetch();
            toast.success(t("event_updated"));
          }
        });
      } else {
        await addEvent({ ..._event, isApproved: false }).then((__event) => {
          if (!__event) {
            setPendingApiCall(false);
            toast.error(t("error_try_again"));
          } else {
            setPendingApiCall(false);
            setEventModal(false);
            setEventForm({});
            fetch();
            toast.success(t("event_added"));
          }
        });
      }
    });
  };

  const onFieldClose = () => {
    setFieldModal(false);
  };

  const onFieldSubmit = () => {
    console.log(fieldForm);
  };

  const accordArray = [0, 1, 2];

  const onClickDownloadInfos = (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Box className={classes.container}>
      <NavBar color={"#ffd283"} />
      <City className={classes.city + " d-none"} />
      <Modal open={eventmodal} onClose={onEventClose}>
        <EventSubmit
          form={eventForm}
          setForm={setEventForm}
          submit={onEventSubmit}
          onDismiss={onEventClose}
          isAdmin={false}
          pendingApiCall={pendingApiCall}
        />
      </Modal>
      <Container maxwidth="lg">
        <Stack
          display="flex"
          direction="column"
          sx={{ paddingTop: "5%" }}
          spacing={2}
        >
          <Stack display="flex" direction="row" justifyContent="space-between">
            <Typography
              variant="h4"
              color="#df0c63"
              className={classes.titleStroke}
            >
              ORGANİZATÖR PANELİ
            </Typography>

            <Button
              onClick={() => logout()}
              variant="contained"
              sx={{
                display: "block",
                color: "#df0c62",
                backgroundColor: "transparent",
                "&:hover": { backgroundColor: "#df0c62", color: "#fff" },
              }}
            >
              Çıkış
            </Button>
          </Stack>
          {/* Search Bar
            <SearchBar />*/}
          {/* Make New Event */}
          {activeEvent ? (
            <Button
              onClick={() => navigate("/organizer/currentEvent")}
              variant="contained"
              sx={{
                backgroundColor: "#df0c62",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              mevcut etkinlik
            </Button>
          ) : null}

          {dashboard ? (
            <>
              <Stack
                display="flex"
                direction="row"
                sx={{
                  width: "100%",
                  backgroundColor: "#ffd283",
                }}
                spacing={2}
              >
                <Button
                  onClick={() => {
                    setEventModal(true);
                  }}
                  variant="contained"
                  sx={{
                    backgroundColor: "#feb659",
                    "&:hover": {
                      backgroundColor: "#feb659",
                      boxShadow: "0px 0px 5px 1px #df0c62",
                      cursor: "pointer",
                    },
                  }}
                  disableElevation
                >
                  <Add />
                </Button>
                <Box
                  flex={1}
                  sx={{
                    backgroundColor: "#df0c62",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: "8px",
                  }}
                >
                  <Typography color="white">YENİ ETKİNLİK OLUŞTUR</Typography>
                </Box>
                {/* End Of Make New Event */}
              </Stack>

              {/* Current Events */}
              {notEndedEvents.length ? (
                <Stack
                  display="flex"
                  direction="column"
                  sx={{ backgroundColor: "#fff0d7", padding: "32px" }}
                >
                  <Typography variant="h5" color="#df0c62" fontWeight="bold">
                    EN YAKIN ETKİNLİKLER
                  </Typography>
                  {/* Beginning Of Accordions */}
                  {notEndedEvents.map((ar, ind) => {
                    return (
                      <div style={{ position: "relative" }}>
                        <Accordion
                          elevation={0}
                          className={ar.isApproved ? "" : "disabledListItem"}
                          sx={{
                            backgroundColor: "transparent",
                            borderBottom: "2px solid #feb659",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <VisibilityOutlined sx={{ color: "#feb659" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography fontWeight="bold" color="#df0c62">
                              {ar.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {/* <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          Suspendisse malesuada lacus ex, sit amet blandit leo
                          lobortis eget.
                        </Typography> */}
                            <CurrentEvent
                              edit={editEvent}
                              refetch={fetch}
                              e_event={ar}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  })}
                </Stack>
              ) : null}

              {endedEvents.length ? (
                <Stack
                  display="flex"
                  direction="column"
                  sx={{ backgroundColor: "#fff0d7", padding: "32px" }}
                >
                  <Typography variant="h5" color="#df0c62" fontWeight="bold">
                    GEÇMİŞ ETKİNLİKLER
                  </Typography>
                  {/* Beginning Of Accordions */}
                  {endedEvents.map((ar, ind) => {
                    return (
                      <div style={{ position: "relative" }}>
                        <Accordion
                          elevation={0}
                          className={ar.isApproved ? "" : "disabledListItem"}
                          sx={{
                            backgroundColor: "transparent",
                            borderBottom: "2px solid #feb659",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <VisibilityOutlined sx={{ color: "#feb659" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography fontWeight="bold" color="#df0c62">
                              {ar.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {/* <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          Suspendisse malesuada lacus ex, sit amet blandit leo
                          lobortis eget.
                        </Typography> */}
                            <CurrentEvent
                              edit={editEvent}
                              refetch={fetch}
                              e_event={ar}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  })}
                </Stack>
              ) : null}

              <Stack
                display="flex"
                direction="column"
                padding={5}
                spacing={2}
                style={{
                  minHeight: "fit-content!important",
                  backgroundColor: "#fff0d7",
                  height: "fit-content!important",
                  maxHeight: "90vh",
                }}
                className={classes.scrollable}
              >
                <Typography variant="h5" color="#df0c62" fontWeight="bold">
                  ÜCRET BİLGİLERİ
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#fff0d7",
                  }}
                >
                  <Stack
                    display="flex"
                    direction="column"
                    paddingTop="20px"
                    spacing={2}
                  >
                    <Stack
                      display="flex"
                      direction="row"
                      sx={{
                        borderBottom: "2px solid #df0c62",
                        padding: "0 16px",
                        textAlign: "center",
                      }}
                    >
                      <Box flex={1} color="#df0c62">
                        <Typography variant="h6" fontWeight="bold">
                          Etkinlik
                        </Typography>
                      </Box>
                      <Box flex={1} color="#df0c62">
                        <Typography variant="h6" fontWeight="bold">
                          Bilet
                        </Typography>
                      </Box>
                      <Box flex={1} color="#df0c62">
                        <Typography variant="h6" fontWeight="bold">
                          Komisyon
                        </Typography>
                      </Box>
                      <Box flex={1} color="#df0c62">
                        <Typography variant="h6" fontWeight="bold">
                          Kazanç
                        </Typography>
                      </Box>
                      <Box flex={1} color="#df0c62">
                        <Typography variant="h6" fontWeight="bold">
                          Ödenilecek
                        </Typography>
                      </Box>
                      <Box flex={1} color="#df0c62">
                        <Typography variant="h6" fontWeight="bold">
                          Gelir
                        </Typography>
                      </Box>
                      {/* End of Data Title */}
                    </Stack>

                    {promoted.length ? (
                      <>
                        {promoted.map((eve, ind) => (
                          <Box
                            key={ind}
                            sx={{ borderBottom: "2px solid #df0c62" }}
                          >
                            <Accordion
                              sx={{
                                backgroundColor: "#fff0d7",
                                boxShadow: "none",
                              }}
                              onChange={() => toggleEve(ind)}
                              expanded={eve.active || false}
                            >
                              <AccordionSummary
                                sx={{ paddingRight: "0", textAlign: "center" }}
                                expandIcon={
                                  <ExpandMore sx={{ color: "#df0c62" }} />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Stack
                                  display="flex"
                                  direction="row"
                                  width="100%"
                                >
                                  <Box flex={1} color="#df0c62">
                                    <Typography fontWeight="bold">
                                      {eve.title}
                                    </Typography>
                                  </Box>
                                  <Box flex={1} color="#df0c62">
                                    <Typography
                                      fontWeight="bold"
                                      sx={{ paddingLeft: "4px" }}
                                    >
                                      {showDiscountPrice(eve.soldTickets)}
                                    </Typography>
                                  </Box>
                                  <Box flex={1} color="#df0c62">
                                    <Typography
                                      fontWeight="bold"
                                      sx={{ paddingLeft: "6px" }}
                                    >
                                      {eve.commission}%
                                    </Typography>
                                  </Box>
                                  <Box flex={1} color="#df0c62">
                                    <Typography
                                      fontWeight="bold"
                                      sx={{ paddingLeft: "6px" }}
                                    >
                                      <div>
                                        {showDiscountPrice(eve.total?.total)}{" "}
                                        {eve.currency}
                                      </div>
                                      {eve.currency !== "EUR" && (
                                        <div>
                                          {showDiscountPrice(
                                            eve.total?.totalEur
                                          ) || "__"}{" "}
                                          {"EUR"}
                                        </div>
                                      )}
                                    </Typography>
                                  </Box>

                                  <Box flex={1} color="#df0c62">
                                    <Typography
                                      fontWeight="bold"
                                      sx={{ paddingLeft: "8px" }}
                                    >
                                      {showDiscountPrice(eve.total?.dept)}{" "}
                                      {eve.currency}
                                    </Typography>
                                  </Box>
                                  <Box flex={1} color="#df0c62">
                                    <Typography
                                      fontWeight="bold"
                                      sx={{ paddingLeft: "8px" }}
                                    >
                                      <div>
                                        {showDiscountPrice(eve.total?.proearn)}{" "}
                                        {eve.currency}
                                      </div>
                                      {eve.currency !== "EUR" && (
                                        <div>
                                          {eve.total?.proearnEur || "__"}{" "}
                                          {"EUR"}
                                        </div>
                                      )}
                                    </Typography>
                                  </Box>
                                  {/* End of Data Title */}
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div
                                  className="container-fluid my-4"
                                  style={{ color: "#df0c62" }}
                                >
                                  <hr />
                                  <div className="row">
                                    <div className="col-6 overall">
                                      <h5 style={{ width: "100%" }}>
                                        Bilet türü:
                                      </h5>
                                      {eve.types.map((ttype, tind) => (
                                        <div
                                          key={tind}
                                          style={{
                                            width: "80%",
                                            marginLeft: "auto",
                                          }}
                                        >
                                          {ttype.title} - {ttype.price}{" "}
                                          {eve.currency} -{" "}
                                          <strong>
                                            {showDiscountPrice(ttype.sold)} (
                                            {showDiscountPrice(
                                              ttype.sold * ttype.price
                                            )}{" "}
                                            {eve.currency})
                                          </strong>
                                        </div>
                                      ))}
                                      <hr />
                                      <h5 style={{ width: "100%" }}>
                                        Ödeme türü:
                                      </h5>
                                      <div
                                        style={{
                                          width: "80%",
                                          marginLeft: "auto",
                                        }}
                                      >
                                        Kart - {eve.total.cardVal} -{" "}
                                        <strong>
                                          {showDiscountPrice(eve.total.card)}{" "}
                                          {eve.currency}
                                        </strong>
                                      </div>
                                      <div
                                        style={{
                                          width: "80%",
                                          marginLeft: "auto",
                                        }}
                                      >
                                        Nakit - {eve.total.cashVal} -{" "}
                                        <strong>
                                          {showDiscountPrice(eve.total.cash)}{" "}
                                          {eve.currency}
                                        </strong>
                                      </div>
                                      <div
                                        style={{
                                          width: "80%",
                                          marginLeft: "auto",
                                        }}
                                      >
                                        Misafir - {eve.total.guestVal} -{" "}
                                        <strong>
                                          {showDiscountPrice(eve.total.guest)}{" "}
                                          {eve.currency}
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="col-6 stat">
                                      <div
                                        style={{
                                          borderBottom: "1px solid #df0c62",
                                          width: "100%",
                                          height: "30px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        Satış / Katılım:{" "}
                                        <strong style={{ float: "right" }}>
                                          {showDiscountPrice(
                                            eve.soldToUsed.sold
                                          )}{" "}
                                          /{" "}
                                          {showDiscountPrice(
                                            eve.soldToUsed.used
                                          )}
                                        </strong>
                                      </div>
                                      <div
                                        style={{
                                          borderBottom: "1px solid #df0c62",
                                          width: "100%",
                                          height: "30px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        Toplam Kazanç:{" "}
                                        <strong style={{ float: "right" }}>
                                          <span>
                                            {showDiscountPrice(eve.total.total)}{" "}
                                            {eve.currency}
                                          </span>
                                          {eve.currency !== "EUR" && (
                                            <>
                                              <span> - </span>
                                              <span>
                                                {showDiscountPrice(
                                                  eve.total.totalEur
                                                ) || "__"}{" "}
                                                {"EUR"}
                                              </span>
                                            </>
                                          )}
                                        </strong>
                                      </div>
                                      <div
                                        style={{
                                          borderBottom: "1px solid #df0c62",
                                          width: "100%",
                                          height: "30px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        Platform Kazancı ({eve.commission}%):{" "}
                                        <strong style={{ float: "right" }}>
                                          <span>
                                            {showDiscountPrice(
                                              eve.total.earning
                                            )}{" "}
                                            {eve.currency}
                                          </span>
                                          {eve.currency !== "EUR" && (
                                            <>
                                              <span> - </span>
                                              <span>
                                                {showDiscountPrice(
                                                  eve.total.earningEur
                                                ) || "__"}{" "}
                                                {"EUR"}
                                              </span>
                                            </>
                                          )}
                                        </strong>
                                      </div>
                                      <div
                                        style={{
                                          borderBottom: "1px solid #df0c62",
                                          width: "100%",
                                          height: "30px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        Benim Kazançım:{" "}
                                        <strong style={{ float: "right" }}>
                                          <span>
                                            {showDiscountPrice(
                                              eve.total.proearn
                                            )}{" "}
                                            {eve.currency}
                                          </span>
                                          {eve.currency !== "EUR" && (
                                            <>
                                              <span> - </span>
                                              <span>
                                                {showDiscountPrice(
                                                  eve.total.proearnEur
                                                ) || "__"}{" "}
                                                {"EUR"}
                                              </span>
                                            </>
                                          )}
                                        </strong>
                                      </div>
                                      <div
                                        style={{
                                          borderBottom: "1px solid #df0c62",
                                          width: "100%",
                                          height: "30px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        Ödenilen Toplam:{" "}
                                        <strong style={{ float: "right" }}>
                                          <span>
                                            {showDiscountPrice(
                                              eve.paymentsTotal
                                            ) || 0}{" "}
                                            {eve.currency}
                                          </span>
                                          {eve.currency !== "EUR" && (
                                            <>
                                              <span> - </span>
                                              <span>
                                                {showDiscountPrice(
                                                  eve.paymentsTotalEur
                                                ) || "__"}{" "}
                                                {"EUR"}
                                              </span>
                                            </>
                                          )}
                                        </strong>
                                      </div>
                                      <div
                                        style={{
                                          borderBottom: "1px solid #df0c62",
                                          width: "100%",
                                          height: "30px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        Ödenilecek Miktar:{" "}
                                        <strong style={{ float: "right" }}>
                                          {showDiscountPrice(eve.total.dept)}{" "}
                                          {eve.currency}
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                </div>

                                <Stack
                                  display="flex"
                                  direction="column"
                                  spacing={2}
                                >
                                  <Box
                                    sx={{
                                      borderBottom: "2px solid #ffd283",
                                      padding: "0 16px",
                                    }}
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <Typography
                                        variant="h6"
                                        color="#df0c62"
                                        fontWeight="bold"
                                      >
                                        ÖDEME GEÇMİŞİ
                                      </Typography>
                                      {/* <Typography
                                        className="border border-danger rounded-5 p-2"
                                        color="#df0c62"
                                        fontWeight="bold"
                                        onClick={() =>
                                          onClickDownloadInfos(eve)
                                        }
                                      > */}
                                      <InfosPdf data={eve} />
                                      {/* </Typography> */}
                                    </div>
                                  </Box>
                                  <div className="d-flex flex-column w-100 pb-3">
                                    {eve.payments?.length ? (
                                      <>
                                        {eve.payments.map((payment, pindex) => (
                                          <Box
                                            style={{ width: "100%" }}
                                            key={pindex}
                                          >
                                            <Stack
                                              display="flex"
                                              direction="row"
                                              justifyContent="space-between"
                                              sx={{
                                                cursor: "pointer",
                                                borderBottom:
                                                  "2px solid #ffd283",
                                                padding: "0 16px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                      "space-between",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Typography
                                                    color="#df0c62"
                                                    fontWeight="bold"
                                                  >
                                                    <span>
                                                      {payment.amount}{" "}
                                                      {eve.currency}
                                                    </span>
                                                    {eve.currency !== "EUR" && (
                                                      <>
                                                        <span> - </span>
                                                        <span>
                                                          {payment.amount /
                                                            payment.rate ||
                                                            "__"}{" "}
                                                          {"EUR"}
                                                        </span>
                                                      </>
                                                    )}
                                                  </Typography>

                                                  <Typography
                                                    color="#df0c62"
                                                    fontWeight="bold"
                                                  >
                                                    {payment.created
                                                      .split("T")[0]
                                                      .replaceAll("-", ".")}
                                                  </Typography>
                                                </div>

                                                <div
                                                  style={{
                                                    display: "flex",
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                    justifyContent:
                                                      "space-between",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      width: "100%",
                                                      textAlign: "center",
                                                    }}
                                                    color="#df0c62"
                                                    fontWeight="bold"
                                                  >
                                                    {payment.comment}
                                                  </Typography>
                                                </div>
                                              </div>
                                            </Stack>
                                          </Box>
                                        ))}
                                      </>
                                    ) : (
                                      <Typography
                                        color="#df0c62"
                                        fontWeight="bold"
                                      >
                                        Boş
                                      </Typography>
                                    )}
                                  </div>
                                </Stack>
                              </AccordionDetails>
                            </Accordion>
                            {/* Accordion End */}
                          </Box>
                        ))}
                      </>
                    ) : null}
                  </Stack>
                </Box>
                {/* End Of Organizer Fee Information */}
              </Stack>

              {dashboard ? (
                <React.Fragment>
                  <Stack
                    display="flex"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" fontWeight="bold" color="#df0c62">
                      {"AYLIK GRAFİK"}
                    </Typography>
                    <Typography color="#df0c62" fontWeight="bold">
                      Satılan Toplam Bilet: {dashboard.count} Adet
                    </Typography>
                  </Stack>
                  <Grid
                    container
                    padding="8px 32px"
                    sx={{ gap: { xs: 5, sm: 0 } }}
                  >
                    <Grid
                      sx={{ margin: "auto", marginBottom: "20px" }}
                      item
                      lg={12}
                    >
                      <Stack
                        display="flex"
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          color="#df0c62"
                          sx={{ fontSize: { xs: "0.9rem", sm: "1.5rem" } }}
                        >
                          {"KAZANCIM"}
                        </Typography>
                        <Box
                          className={`${classes.circleBase} ${classes.circle2}`}
                        >
                          <Box className={classes.innerCircle}>
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              color="#df0c62"
                              align="center"
                            >
                              {"" + dashboard.totals.usd + " USD"} <br />
                              {"" + dashboard.totals.gbp + " GBP"} <br />
                              {"" + dashboard.totals.cad + " CAD"} <br />
                              {"" + dashboard.totals.eur + " EUR"} <br />
                              {"" + dashboard.totals.try + " TRY"} <br />
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : null}
            </>
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "50px",
                }}
              >
                <Box
                  className={`${classes.circleBase} ${classes.circle2} rotating`}
                >
                  <Box className={classes.innerCircle}></Box>
                </Box>
              </div>
            </>
          )}
        </Stack>
      </Container>
      <div>
        {isMobile ? (
          <FooterMobile />
        ) : isBigScreen ? (
          <Footer />
        ) : (
          <FooterTablet />
        )}
      </div>
      {isMobile && <MobileNavBar />}
    </Box>
  );
};

export default Desktop;
