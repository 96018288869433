import React, { useState } from 'react'
import TopBar from '../AppBar/TopBar'
import { useTranslation } from 'react-i18next'
import { reportProblem } from '../../Data/report'
import Footer from '../Panel/Footer/Footer'
import { Opacity } from '@mui/icons-material'
import { toast } from 'react-toastify'

const ReportProblem = () => {

    const { t } = useTranslation()
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [type, setType] = useState('')
    const [description, setDescription] = useState('')
    const [isPending, setIsPending] = useState(false)

    const onClickSend = async () => {
        if (!(fullName && email && phone && type)) {
            toast.error(t("fill_inputs"))
            return;
        }
        setIsPending(true)
        const body = {
            fullName, email, phone, type, description
        }
        console.log(body)
        try {
            const p = await reportProblem(body)
            toast.success(t("message_delivered"))
            setFullName("")
            setEmail("")
            setPhone("")
            setType("")
            setDescription("")
        } catch (error) {

        } finally {
            setIsPending(false)
        }

    }

    const buttonStyle = {
        width: "100%",
        backgroundColor: "#df0c62",
        border: "none",
        color: "#fff",
        padding: "10px",
        letterSpacing: "3px",
        fontWeight: "500",
        opacity: isPending ? "0.5" : "1"
    }

    return (
        <div className="relative" style={{ height: 'screen' }}>
            <TopBar color="#ffd283" />
            <div className="container" style={{ marginTop: '100px', marginBottom: "100px" }}>
                <h2 className="mb-5 ms-2" style={{ color: "#df0c62", textTransform: "uppercase" }}>
                    {t('Report a Problem')}
                </h2>
                <div className="mb-3">
                    <input
                        className='modalFormInput w-100'
                        placeholder={t('Your full name')}
                        onChange={e => setFullName(e.target.value)}
                        value={fullName}
                    />
                </div>
                <div className="mb-3">
                    <input
                        className='modalFormInput w-100'
                        placeholder={t('Your email address')}
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                    />
                </div>
                <div className="mb-3">
                    <input
                        className='modalFormInput w-100'
                        placeholder={t('Your phone number')}
                        onChange={e => setPhone(e.target.value)}
                        value={phone}
                    />
                </div>
                <div className="mb-3">
                    <select className='px-2 py-3 modalFormInput w-100' defaultValue={""} onChange={e => setType(e.target.value)}>
                        <option value="" disabled>{t('Choose')}</option>
                        <option>{t('Payment Problem')}</option>
                        <option>{t('Ticket Delivery')}</option>
                        <option>{t('Other')}</option>
                    </select>
                </div>
                <div className="mb-3">
                    <textarea
                        className='p-2 modalFormInput w-100'
                        placeholder={t('Description')}
                        onChange={e => setDescription(e.target.value)}
                        value={description}
                    />
                </div>
                <button onClick={onClickSend} style={buttonStyle} disabled={isPending}>
                    {t('Submit')}
                </button>
            </div>

            <div style={{ bottom: 0, width: '100%' }}>
                <Footer />
            </div  >

        </div >
    )
}

export default ReportProblem